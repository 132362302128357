.state-authorites-inputs{
    display: flex;
    flex-direction: column;
    gap: 20px; 
    
  }
  
  .input-group {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between the label and the select field */
  }
  
  .input-group label {
    flex: 1; /* Adjust the flex value to set the desired width */
    min-width: 100px; /* Ensure label doesn't shrink too much */
  }
  
  .input-group select {
    flex: 2; /* Adjust the flex value to set the desired width */
  }
  .big-input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .select-input-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .labels {
    font-size: 16px;
  }
  .bank-checkbox-labels {
    font-size: 16px;
    margin-left: 10px;
  }
  .bank-flex-input-boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 40px;
  }
  .project-flex-inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    white-space: nowrap;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .label-input-flex {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 5px;
  }
  .checkbox-flex {
    gap: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .price-fields {
    display: flex;
    justify-content: left;
    align-items: left;
    gap: 10px;
    margin-top: 20px;
  }
  .facing {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .textarea-input {
    max-width: 50%;
    min-width: 50%;
    border-radius: 5px;
    max-height: 100px;
    min-height: 100px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 16px;
    outline: 1px;
  }
  
 
  
  