.home-page {
  width: 100%;
  height: 100%;
  /* background-color: yellow; */
  /* background-color: #f0f2fa; */
}

.home-page .cards-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  padding: 0px 10px;
  width: 100%;
  align-items: center;
  /* margin-top: 20px; */
  /* background-color: red; */
}

.home-page .mid-section {
  /* background-color: yellow; */
  padding: 1rem;
  display: flex;
  gap: 1rem;
  display: flex !important;
}

.mid-section .bigchart-container {
  /* background-color: aliceblue; */
  border: 1px solid #e0e0e0;
  border-radius: 0.3rem;
  padding: 1rem;
  flex: 1;
}
.mid-section .bigchart-container .top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mid-section .bigchart-container .bottom-section {
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
}
.mid-section .bigchart-container .bottom-section .minor-charts {
  width: 100%;
  /* flex-wrap: wrap; */
}
.minor-charts .minor-charts-top {
  height: 5rem;
}
.minor-charts .minor-charts-bottom {
  margin-top: 3rem;
  height: 5rem;
}
.mid-section .two-charts-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background-color: aliceblue; */
}

.two-charts-container .top-chart,
.two-charts-container .bottom-chart {
  border: 1px solid #e0e0e0;
  border-radius: 0.3rem;
  padding: 1rem;
}

.top-chart .bottom-section,
.bottom-chart .bottom-section {
  width: 100%;
  height: 15rem;
  display: flex;
  align-items: center;
}

.map-section {
  border: 1px solid #e0e0e0;
  border-radius: 0.3rem;
  padding: 1rem;
  margin: 1rem;
}

.bottom-section {
	/* background-color: rgb(246, 245, 245); */
	display: flex;
	flex-direction: column;
	box-shadow: 0 1px 5px 1px rgb(185, 184, 184);
	/* width: 200px; */
	padding-bottom: 60px;
	border-radius: 0.3rem;
	width: 100px
}
