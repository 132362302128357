.container {
  position: relative;
  width: 100%; /* Adjust width if needed */
  height: 100%; /* Adjust height if needed */
}

.svg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shape {
  fill: rgba(255, 0, 0, 0.55); /* Semi-transparent green fill */
  stroke: red; /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
  animation: blink 1s infinite; /* Blinking effect */
}
.completed {
  fill: rgb(0, 255, 17, 0.5); /* Semi-transparent green fill */
  stroke: green; /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
}
.new-path {
  fill: rgba(255, 217, 0, 0.5); /* Semi-transparent green fill */
  stroke: green; /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
}
.selected {
  fill: rgb(0, 30, 255, 0.5); /* Semi-transparent green fill */
  stroke: green; /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
}
.blocked {
  fill: rgba(111, 111, 111, 0.5); /* Semi-transparent green fill */
  stroke: black; /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
}
.booked {
  fill: rgba(0, 85, 255, 0.25); /* Semi-transparent green fill */
  stroke: rgb(0, 0, 255); /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
}

.shape:hover {
  fill: rgba(255, 225, 0, 0.25); /* Semi-transparent red fill on hover */
  cursor: pointer;
  stroke: rgba(255, 225, 0); /* Red stroke on hover */
  stroke-width: 3; /* Increased stroke width on hover (optional) */
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
