.login_content {
  display: block;
  justify-content: center;
  align-items: center;
  width: 400px !important;
}
.login_img{
  margin: 0 auto !important;
  width: 550px !important;
  background-color: #ffe0cc !important;
 
}
.login_img img {
  border-radius: 10px;
}
.login_heading {
  text-align: center;
}
.form {
  width: 350px;
  height: auto;
  display: block;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.btn {
  
  font-family: "Montserrat", serif;
}
.login-container{
  background-color: #fefcf4 !important; 
  
  
}
.login_form{
  margin: 0 auto !important;
  width: 1000px !important;
  height: auto !important;
 background-color: white !important;
 display: grid !important;
 grid-template-columns: repeat(2, 1fr) !important;
}
.incorners-logo-container{
 width: 100%;
height: 40px;
 display: flex;
 justify-content: center;
 align-items: center;

}
.login_heading h1{
  color:#414040 !important;
  font-weight: bold !important;
}
.input-field-with-icon{
  width: 350px !important;
  margin-top: 30px !important;
}
.btn{
  
  
  background: linear-gradient(60deg, #ff8522, #fea052) !important;
}
.btn:hover{
  background-color: #f08935 !important;
}