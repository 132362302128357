.employee-container{
    width: 100% !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: right !important;
    align-items: right !important;
    margin-bottom: 12px !important;
    gap: 10px !important;
}

#role-select-label{
    color: green !important;
    font-size: 16px !important;
}