.add-user-btn{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    align-items: right;
    margin-bottom: 12px;
    gap: 10px !important;
}
.button:hover{
    background-color: #49BB43;
}