.input-field-container{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    
    margin-bottom: 20px;
}
.logo-container{
    
    display: flex;
    justify-content: center;
    align-items: center;
    width:auto;
    gap: 20px;
    padding: 5px 15px;
}