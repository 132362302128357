body {
  margin: 0;
  font-family: "Montserrat", serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: #4e4e4e !important;
  background-color: #faf7f7;
}

.glb-tab-panel {
  margin-bottom: 1.5rem !important;
}

.glb-tab-panel .MuiTab-textColorPrimary.Mui-selected {
  /* color: #28a745 !important; */
  color: #007427 !important;
  /* background-color: rgba(40, 167, 69, 0.12549019607843137) !important; */
  background-color: #d7ffe4 !important;
}

.green-contained-button {
  background-color: #d7ffe4 !important;
  border-width: 0.8px !important;
  font-weight: 500 !important;
  border-color: #009933 !important;
  border-radius: 20px !important;
  color: #009933 !important;
  text-transform: capitalize !important;
  font-family: "Montserrat", serif !important;
}

.green-outlined-button {
  padding: 2px 8px !important;
  font-size: small;
  font-weight: 500 !important;
  background-color: #e3f4d9 !important;
  color: #009933 !important;
  border-color: #01882e !important;
  text-transform: capitalize !important;
  font-family: "Montserrat", serif !important;
}

.btn {
  /* background-color: #55912f !important; */
  text-transform: capitalize !important;
  font-family:   "Montserrat", serif !important;
  color: white !important;
  font-size: 15px !important;
  padding: 10px 8px !important;
}
.btn:hover {
  background-color: #e8812d !important;
}

/* .btn:hover {
  background-color: rgb(41, 134, 41) !important;
} */

.form-group {
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: start;
  margin-left: 19px;
  align-items: center;
  gap: 10px;
}

.label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-with-unit {
  display: flex;
  align-items: center;
  width: 70px;
}

.number-input {
  flex: 2;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  font-size: large;
}

.unit-select {
  flex: 1;
  padding: 15px;
  border: 1px solid #ccc;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  background-color: #fff;
  font-size: large;
}

body {
  font-family: "Montserrat", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.download-btn {
  background-color: #383838 !important;
  color: white !important;
  text-transform: capitalize !important;
}

.download-btn:hover {
  background-color: #4e4e4e !important;
}

/* calendar styles */

.rbc-header {
  background-color: #119e40 !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: white !important;
  font-size: 15px !important;
}

.rbc-allday-cell {
  display: none !important;
}

.rbc-rows {
  height: 500px !important;
}

.rbc-time-header-cell .rbc-today {
  background-color: rgb(195, 255, 195) !important;
  color: black !important;
}

.rbc-time-slot {
  min-height: 30px !important;
  /* min-width: 80px !important; */
  color: #009933 !important;
  font-size: 13px !important;
}

.rbc-label {
  font-size: 12px !important;
}

/* .rbc-events-container{
    width: 300px !important;
    height: 80px !important;
    background-color: #007427;
  } */

.dark-green-button {
  background-color: #007427 !important;
  color: white !important;
  text-transform: capitalize !important;
}
.dark-red-button {
  background-color: #e40f0f !important;
  color: white !important;
  text-transform: capitalize !important;
}

/* UploadLayout.css */

/* Container that holds the zoomable content */
.zoom-container {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: "auto"; /* Fixed width */
  height: "auto"; /* Fixed height */
  overflow: auto; /* Enable scrolling when content overflows */
  border: 1px solid #ccc; /* Optional: Add a border */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f0f0f0; /* Optional: Add a background color */
}

/* Content inside the container (image and SVG) */
.zoom-content {
  display: inline-block; /* Ensures proper scaling */
  position: relative;
}

.dark-button {
  background-color: #383838 !important;
  color: white !important;
  text-transform: capitalize !important;
}

.button-orange {
  margin-top: 20px !important;
  width: 350px !important;
  height: 50px !important;
  font-weight: bold !important;
  font-family: "Montserrat", serif !important;
  background-color: #ff9037 !important;

  text-transform: capitalize !important;
  font-size: 18px !important;
}
.button-orange:hover {
  background-color: #e27922;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.inline-flex {
  display: flex;
  align-items: center;
}
.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-group{
  display: flex !important;
  justify-content: start !important;
  margin-bottom: 20px !important;
  gap: 10px !important;
}

  .image-preview-container {
    margin-top: 1rem !important;
    max-width: 150px !important;
    max-height: 150px !important;
    overflow: hidden !important;
    position: relative !important;
  }

  .image-preview-container:hover .remove-button {
   
    opacity: 1 !important; /* Show the button on hover */
  }
  .image-preview{
    max-width: 100% !important;
    max-height: 100% !important;
    border-radius: 5px !important;
    object-fit: cover !important;
  }
  .remove-button {
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
    background-color: rgba(0, 0, 0, 0.7) !important; /* Semi-transparent background */
    color: white !important;
    min-width: 30px !important;
    min-height: 30px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    cursor: pointer !important;
    opacity: 0 !important; /* Initially hidden */
    transition: opacity 0.3s ease !important; /* Smooth transition effect */
  }
  .input-container{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .image-grid-box{
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    align-items: center!important;
    justify-content: center !important;
    gap: 5px !important;
    background-color: #f0f0f0 !important;
    padding: 10px !important;
    margin-top: 10px !important;
  }

  .button-container{
    display: flex !important;
    justify-content: right !important;
    align-items: right !important;
  }

  .input-field-container{
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
    /* padding: 20px !important; */
  }


  .mid-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    background-color: #f7f9fc;
  }
  
  .bigchart-container {
    width: 100%;
    max-width: 1200px;
    padding: 1.5rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .top-section h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 1rem;
  }
  
  .charts-wrapper {
    display: flex;
    gap: 2rem; /* Space between the charts */
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .chart-container {
    flex: 1;
    padding: 1.5rem;
    background: linear-gradient(145deg, #ffffff, #f0f0f3);
    border-radius: 12px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(255, 255, 255, 0.7);
    text-align: center;
  }
  
  .chart-container h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    color: #555555;
    margin-bottom: 1rem;
  }
  
  .pie-chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .bar-chart-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .date-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    color: #666666;
  }
  
  .date-navigation span {
    font-weight: 500;
  }
  
  .date-navigation .MuiIconButton-root {
    color: #666666;
  }
  