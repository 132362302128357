.main-container{
   
    margin-left: auto;
    margin-right: auto;
}




.verify-login-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.verify-form{
    width: 400px;
    height: 400px;
    background-color: #fafafa;
    box-shadow: 3px 3px 3px 3px rgb(216, 215, 215);
    text-align: center;
}
.verify-form h2{
    margin-top: 15px;
}
.para{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    text-align: center;
}
.otp-inputs{
    width: 50%;
    margin: 30px auto;
    margin-top: 20px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    
    input{
        width: 40px;
        padding: 10px;
        outline: none;
        text-align: center;
        background-color: rgb(237, 237, 237);
        border-radius: 5px;
        border: none;
    }
}
.para2{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    text-align: left;
    margin-left: 36px;
}
.resend-link{
    text-decoration: none;
    color: green;
    font-weight: 500;
}