.MuiOutlinedInput-input{
    
    border-color: red !important;
    font-family:  "Montserrat", serif !important;
    
}

.MuiOutlinedInput-input.Mui-active{
    background-color: red !important;
    border: red !important;
    outline: blue !important;
}


/* .input:-internal-autofill-selected {
    background-color: blue !important;
} */