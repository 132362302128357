.ag-theme-alpine .ag-row{
    font-size: 13px !important;
    font-weight: 500 !important;
    color:rgb(82, 82, 82) !important;
    font-family: "Work Sans", sans-serif !important;
}
.ag-theme-alpine{
    --ag-alpine-active-color: #55912f !important;
    --ag-selected-row-background-color: #d8fac5 !important;
    --ag-row-hover-color: #e8f9df !important;
}
.ag-theme-alpine .ag-header-row {
color: rgb(82, 82, 82) !important;
font-weight: 500 !important;
font-family: "Montserrat", serif !important;
}