

.login {
    height: 100vh;
    width: 100%;
    /* background: radial-gradient(#653d84, #332042); */
    position: relative;
}

.login_box {
    width: 1050px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 4px 22px -8px #0004;
    display: flex;
    overflow: hidden;
}

.login_box .left {
    width: 41%;
    height: 100%;
    padding: 25px 25px;
}

.login_box .right {
    width: 59%;
    height: 100%;
    background: linear-gradient(212.38deg, rgba(242, 57, 127, 0.7) 0%, rgba(175, 70, 189, 0.71) 100%), url(https://t4.ftcdn.net/jpg/05/58/63/67/360_F_558636776_STYyj0JdaTqT2IpImU0Fn8B7DvwlXfMb.jpg) no-repeat center center;
    background-size: cover;
    color: #fff;
    position: relative;
}

.left .top_link a {
    color: #452A5A;
    font-weight: 400;
}


.left .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 70%;
    width: 78%;
    margin: auto;
    margin-top: 50px;
}

.left h3 {
    text-align: center;
    margin-bottom: 40px;
}

.phone-container {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%;
}

.flag-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.flag {
    width: 24px;
    height: auto;
    margin-right: 10px;
}

.country-code {
    font-size: 16px;
    font-weight: 600;
}

.phone-input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 16px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.submit {
    border: none;
    padding: 15px 70px;
    border-radius: 8px;
    display: block;
    margin: auto;
    background: #583672;
    color: #fff;
    font-weight: bold;
    box-shadow: 0px 9px 15px -11px rgba(88, 54, 114, 1);
    cursor: pointer;
}

.right .right-text {
    height: 100%;
    position: relative;
    transform: translate(0%, 45%);
}

.right-text h2 {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: 500;
}

.right-text h5 {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 19px;
    font-weight: 400;
}

.right .right-inductor {
    position: absolute;
    width: 70px;
    height: 7px;
    background: #fff0;
    left: 50%;
    bottom: 70px;
    transform: translate(-50%, 0%);
}

.top_link img {
    width: 28px;
    padding-right: 7px;
    margin-top: -3px;
}
