.labels {
  font-size: 16px;
}
.bank-checkbox-labels {
  font-size: 16px;
  margin-left: 10px;
}
.bank-flex-input-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 40px;
}
.project-flex-inputs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  white-space: nowrap;
  margin-top: 30px;
  margin-bottom: 30px;
}
.label-input-flex {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  gap: 5px;
}
.checkbox-flex {
  gap: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price-fields {
  display: flex;
  justify-content: left;
  align-items: left;
  gap: 10px;
  margin-top: 20px;
}
.facing {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.textarea-input {
  max-width: 50%;
  min-width: 50%;
  border-radius: 5px;
  max-height: 100px;
  min-height: 100px;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 16px;
  outline: 1px;
}

.big-input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
