.svg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shape {
  fill: rgba(0, 255, 0, 0.5); /* Semi-transparent green fill */
  stroke: green; /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
}

.new-path {
  fill: rgb(0, 255, 17); /* Semi-transparent green fill */
  stroke: green; /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
}
.selected {
  fill: rgb(0, 30, 255, 0.25); /* Semi-transparent green fill */
  stroke: green; /* Green stroke */
  stroke-width: 2; /* Stroke width */
  transition: fill 0.3s, stroke 0.3s; /* Smooth transition for hover effect */
}

.shape:hover {
  fill: rgba(255, 0, 0, 0.5); /* Semi-transparent red fill on hover */
  stroke: red; /* Red stroke on hover */
  stroke-width: 3; /* Increased stroke width on hover (optional) */
}
